<template lang="pug">
  .vue-pouvoir-vaucanson(:class="{ error }")
    copie-pouvoir-en-cours(v-if="solved" names="vaucanson")
    div(v-else)
      img.w-100.p-absolute.bg(src="@/assets/pouvoirs/vaucanson/fond.jpg" srcset="@/assets/pouvoirs/vaucanson/fond@2x.jpg 2x")
      menu-button
      .text-block.titre {{$t('pouvoirs.vaucanson.titre')}}
      svg.p-absolute(ref="svg" viewBox="0 0 1920 1080" width="100%" height="100%")
        g#case_01.case(@click="toggle(0)" :class="{ selected: isSelected(0) }")
          path(d="M342.09,542L659.29,542L625.79,786L419.42,786L342.09,542Z")
          text.number(x="495" y="510") 1
        g#case_02.case(@click="toggle(1)" :class="{ selected: isSelected(1) }")
          path(d="M691.912,542.21L907.005,542.21L983.202,786.182L659.286,786.182L691.912,542.21Z")
          text.number(x="780" y="510") 2
        g#case_03.case(@click="toggle(2)" :class="{ selected: isSelected(2) }")
          path(d="M941,542.21L1258.2,542.21L1224.7,786.182L1018.33,786.182L941,542.21Z")
          text.number(x="1080" y="510") 3
        g#case_04.case(@click="toggle(3)" :class="{ selected: isSelected(3) }")
          path(d="M1290.822,542L1505.915,542L1582.112,786L1258.196,786L1290.822,542Z")
          text.number(x="1375" y="510") 4

      .notes
        .note.note-1(:class="{ selected: isSelected(0) }") {{ values[0] || notes[0] }}
        .note.note-2(:class="{ selected: isSelected(1) }") {{ values[1] || notes[1] }}
        .note.note-3(:class="{ selected: isSelected(2) }") {{ values[2] || notes[2] }}
        .note.note-4(:class="{ selected: isSelected(3) }") {{ values[3] || notes[3] }}
      #replay-indicator(v-if="replayIndex >= 0" :class="`p${replayIndex + 1}`")
        img(src="@/assets/pouvoirs/vaucanson/note.png")

      button.validate(@click="validate") {{$t('pouvoirs.vaucanson.valider')}}
</template>

<script>
import MenuButton from '@/components/MenuButton.vue'
import CopiePouvoirEnCours from '@/components/CopiePouvoirEnCours.vue'

const NOTES = {
  do: 262,
  re: 294,
  mi: 330,
  fa: 349,
  sol: 392,
  la: 440,
  si: 494
}
const SOLUTION = 'do,fa,mi,re'
const NOTE_DURATION_SEC = 0.5

let audioContext = null
let oscillatorNode = null
let stopTime = 0

function tone(frequency, durationSec) {
  if (!oscillatorNode) {
    audioContext = new (window.AudioContext || window.webkitAudioContext)()
    stopTime = audioContext.currentTime
    oscillatorNode = audioContext.createOscillator()
    oscillatorNode.type = 'sine'
    oscillatorNode.connect(audioContext.destination)
    oscillatorNode.start()
    oscillatorNode.onended = function() {
      oscillatorNode = null
      audioContext = null
    }
  }
  oscillatorNode.frequency.setValueAtTime(frequency, stopTime) // value in hertz
  stopTime += durationSec
  oscillatorNode.stop(stopTime)
}

const sleep = async (ms) => new Promise(resolve => setTimeout(resolve, ms))

export default {
  name: 'VuePouvoirCarson',

  components: {
    MenuButton, CopiePouvoirEnCours
  },

  computed: {
    value() {
      return this.values.join(',')
    }
  },

  mounted () {
    this.start()
  },

  beforeDestroy() {
    this.stop()
  },

  data () {
    return {
      notes: ['do', 're', 'mi', 'sol'],
      values: [null, null, null, null],
      solved: false,
      replayIndex: -1,
      error: false
    }
  },

  methods: {
    start() {
      this.interval = setInterval(() => { this.tick() }, 1000)
    },

    stop() {
      clearInterval(this.interval)
    },

    toggle(n) {
      if (this.values[n]) {
        this.$set(this.values, n, null)
        this.$sound('tick')
      } else {
        this.$set(this.values, n, this.notes[n])
        tone(NOTES[this.notes[n]], NOTE_DURATION_SEC)
      }
    },

    async validate() {
      const result = this.value === SOLUTION
      if (result) {
        this.stop()
        await this.replay(2)
        this.solved = result
      } else {
        this.$sound('error')
        this.reset()
      }
    },

    reset() {
      this.values = [null, null, null, null]
    },

    async replay(times = 1) {
      const melody = this.values.slice()
      for (let j = 0; j < times; j++) {
        melody.forEach((v, i) => setTimeout(
          () => {
            if (v) {
              tone(NOTES[v], NOTE_DURATION_SEC)
              this.replayIndex = i
            }
          },
          (i + j * melody.length) * NOTE_DURATION_SEC * 1000
        ))
      }
      await sleep(times * melody.length * NOTE_DURATION_SEC * 1000)
      this.replayIndex = -1
    },

    isSelected(n) {
      return !!this.values[n]
    },

    nextNote(n) {
      const notes = Object.keys(NOTES)
      let p = notes.indexOf(n)
      if (p === notes.length - 1) p = 0
      else p = p + 1
      return notes[p]
    },

    tick() {
      this.notes.forEach((n, i, notes) => this.$set(notes, i, this.nextNote(n)))
    }
  }
}
</script>

<style lang="less">
.vue-pouvoir-vaucanson {
  #menu-button {
    top: 66px;
    right: 66px;
  }

  #replay-indicator {
    position: absolute;
    top: 640px;
    width: 100px;
    height: 100px;
    background: white;
    border: 5px solid black;
    border-radius: 100%;
    transform: translateX(-50%);
    img {
      margin: 20px;
      width: 60px;
      height: 60px;
    }

    &.p1 { left: 520px }
    &.p2 { left: 810px }
    &.p3 { left: 1120px }
    &.p4 { left: 1410px }
  }

  .validate {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }

  .titre {
    position: absolute;
    top: 70px;
    left: 290px;
    width: 1340px;
  }

  .notes {
    position: absolute;
    .note {
      pointer-events: none;
      position: absolute;
      top: 520px;
      width: 230px;
      font-size: 80px;
      color: #FF249A;
      text-align: center;
      &.selected {
        color: white;
      }
    }
    .note-1 { left: 400px }
    .note-2 { left: 690px }
    .note-3 { left: 1000px }
    .note-4 { left: 1290px }
  }

  svg {
    top: -100px;
    path {
      fill: white;
      stroke: black;
      stroke-width: 8px;
    }

    .selected path {
      fill: #ff6ed3;
      stroke: black;
      stroke-width: 8px;
    }

    text.number {
      font-size: 70px;
      fill: white;
      font-style: italic;
    }
    .case {
      cursor: pointer;
    }

  }
}
</style>
